import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { CrInterventionEvent } from './Types';
import { Box, FormControl } from '@mui/material';
import { ListPage, TimePickerField } from '@applications-terrains/birdz-react-library';
import { formatToSelectOptions, useGetInterventionEvents } from '../../../hooks/datarefs';
import { authService } from '../../..';
import SelectFieldWrapper from '../../Tools/Selects/SelectFieldWrapper';

interface EventsCrInterventionProps {
  id: number;
  crInterventionEvents: CrInterventionEvent[];
  eventToSave(events: CrInterventionEvent): void;
  readOnly?: boolean;
}

const CrInterventionEvents = ({
  crInterventionEvents,
  eventToSave,
  readOnly = false
}: EventsCrInterventionProps) => {
  const [listIsVisible, setListIsVisible] = useState<boolean>(true);
  const { data: interventionEvents } = useGetInterventionEvents();

  useEffect(() => {
    refreshList();
  }, [crInterventionEvents]);

  useEffect(() => {
    setTimeout(() => {
      refreshList();
    });
  }, [readOnly]);

  const listFields = [
    {
      name: 'date',
      label: 'Date',
      transform: (date: string) => {
        return moment(date, 'YYYY-MM-DD HH:mmZ').format('DD/MM/YYYY à HH:mm');
      },
      options: {
        renderForm: () => <TimePickerField size="small" name="date" />
      }
    },
    {
      name: 'step__name',
      label: 'Étape',
      options: {
        renderForm: () => (
          <FormControl sx={{ width: 300 }}>
            <SelectFieldWrapper
              id="pickStep"
              name="step"
              label="Étape"
              options={formatToSelectOptions(interventionEvents)}
            />
          </FormControl>
        )
      }
    }
  ];

  const refreshList = () => {
    setListIsVisible(false);
    setTimeout(() => {
      setListIsVisible(true);
    });
  };

  const onSave = (interventionEvent: CrInterventionEvent) => {
    eventToSave(interventionEvent);
    return Promise.resolve({});
  };

  return (
    <Box>
      <h3>Évènements intervention</h3>
      {listIsVisible && (
        <ListPage
          fields={listFields}
          loadedData={crInterventionEvents}
          inlineEditOptions={
            authService.canAccess('CR_EDIT_INTER')
              ? {
                  onSave: readOnly ? undefined : onSave,
                  validationSchema: Yup.object().shape({
                    date: Yup.date()
                      .typeError("La date n'est pas au bon format")
                      .required('Champ obligatoire'),
                    step: Yup.number().required('Champ obligatoire')
                  })
                }
              : {}
          }
          paginationOptions={{ pageSize: 10 }}
          displayPaginationOptions={false}
          displayResultsNumber={false}
        />
      )}
    </Box>
  );
};

export default CrInterventionEvents;
