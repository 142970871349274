import React, { useEffect } from 'react';
import { FormikErrors, useFormikContext } from 'formik';
import { SelectField } from '@applications-terrains/birdz-react-library';

interface SelectFieldWrapperProps {
  name: string;
  options?: {
    value: any;
    label: any;
  }[];
  label?: string;
  id?: string;
  selectedItems?: any[];
  isMulti?: boolean;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
  required?: boolean;
  disabled?: boolean;
  onChange?: (a: any, b: any) => void;
  errors?: FormikErrors<any>;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const SelectFieldWrapper: React.FC<SelectFieldWrapperProps> = (props: SelectFieldWrapperProps) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values instanceof Object && !(props.name in values)) {
      setFieldValue(props.name, null);
    }
  }, [values, props.name]);

  return <SelectField {...props} />;
};

export default SelectFieldWrapper;
