import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { Box, FormControl } from '@mui/material';
import { Pricing } from '../../../types';
import {
  InputField,
  useNotif,
  showFriendlyErrorsHTML,
  BirdzNotif,
  ListField,
  DatePickerField,
  ListPage
} from '@applications-terrains/birdz-react-library';
import { formatToSelectOptions, useGetYears } from '../../../hooks/datarefs';
import moment from 'moment';
import SelectFieldWrapper from '../../Tools/Selects/SelectFieldWrapper';

const AdditionalPricings = () => {
  const { id } = useParams();
  const endpoint = `/api/boi/subcontractors/subcontractors/${id}/`;
  const deleteAdditionalPricings = '/api/boi/subcontractors/additional-pricing/';
  const createAdditionalPricing = '/api/boi/subcontractors/additional-pricing/';
  const resultsField = 'additional_pricings';
  const [listIsVisible, setListIsVisible] = useState<boolean>(true);
  const { notif, notifOptions } = useNotif();
  const { data: years } = useGetYears();
  const [listFields, setListFields] = useState<ListField[]>([]);

  useEffect(() => {
    const listFields = [
      {
        name: 'year',
        label: 'Année',
        transform: (value: any, row: any) => row?.year__label,
        options: {
          renderForm: () => (
            <FormControl fullWidth>
              <SelectFieldWrapper
                name="year"
                options={years ? formatToSelectOptions(years) : []}
                label="Année"
              />
            </FormControl>
          )
        },
        width: '130px'
      },
      {
        name: 'product_subcontractor_name',
        label: 'Libellé',
        options: {
          renderForm: () => (
            <InputField
              name="product_subcontractor_name"
              type="text"
              label="Libellé"
              size="small"
            />
          )
        },
        width: '250px'
      },
      {
        name: 'product_accounting_code',
        label: 'Code produit (Birdz)',
        options: {
          renderForm: () => (
            <InputField
              name="product_accounting_code"
              type="text"
              label="Code produit (Birdz)"
              size="small"
            />
          )
        },
        width: '250px'
      },
      {
        name: 'product_accounting_name',
        label: 'Libellé (Birdz)',
        options: {
          renderForm: () => (
            <InputField
              name="product_accounting_name"
              type="text"
              label="Libellé (Birdz)"
              size="small"
            />
          )
        },
        width: '250px'
      },
      {
        name: 'price',
        label: 'Montant par unité',
        options: {
          renderForm: () => (
            <InputField name="price" type="number" label="Montant par unité" size="small" />
          )
        }
      },
      {
        name: 'start_date',
        label: 'Date début',
        transform: (date: string) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        options: {
          renderForm: () => (
            <DatePickerField
              name="start_date"
              inputFormat="dd/MM/yyyy"
              time={false}
              label="Date de début"
              size="small"
            />
          )
        },
        width: '190px'
      },
      {
        name: 'end_date',
        label: 'Date fin',
        transform: (date: string) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        options: {
          renderForm: () => (
            <DatePickerField
              name="end_date"
              inputFormat="dd/MM/yyyy"
              time={false}
              label="Date de fin"
              size="small"
            />
          )
        },
        width: '190px'
      },
      {
        name: 'max_usage_limit',
        label: 'Nb max par intervention',
        transform: (data: null | number) => data || '-',
        options: {
          renderForm: () => (
            <InputField
              name="max_usage_limit"
              type="number"
              label="Nb max par intervention"
              size="small"
            />
          )
        },
        width: '190px'
      },
      {
        name: 'capex_opex',
        label: 'C/O',
        options: {
          renderForm: () => (
            <FormControl fullWidth>
              <SelectFieldWrapper
                options={[
                  { value: 'CAPEX', label: 'CAPEX' },
                  { value: 'OPEX', label: 'OPEX' }
                ]}
                name="capex_opex"
                label="C/O"
              />
            </FormControl>
          )
        },
        width: '140px'
      }
    ];
    setListFields(listFields);
  }, [years]);

  const refreshList = () => {
    setListIsVisible(false);
    setTimeout(() => {
      setListIsVisible(true);
    });
  };

  const onSave = (values: Pricing) => {
    const payload = Object.assign({}, values);
    payload.subcontractor = id ? parseInt(id) : undefined;
    if (values?.id) {
      return axios.put(`${createAdditionalPricing}${values.id}/`, payload).then(
        () => {
          notif({
            type: 'success',
            content: 'Le tarif a été modifié avec succès'
          });
          refreshList();
        },
        (error: any) => {
          const errors = error?.response?.data || [];
          notif({
            type: 'error',
            content: showFriendlyErrorsHTML(
              errors,
              'Une erreur est survenue lors de la modification du tarif'
            )
          });
        }
      );
    } else {
      return axios.post(createAdditionalPricing, payload).then(
        () => {
          notif({
            type: 'success',
            content: 'Le tarif a été créé avec succès'
          });
          refreshList();
        },
        (error) => {
          const errors = error?.response?.data || [];
          notif({
            type: 'error',
            content: showFriendlyErrorsHTML(
              errors,
              'Une erreur est survenue lors de la création du tarif'
            )
          });
        }
      );
    }
  };

  const onDelete = (values: Pricing) =>
    axios.delete(deleteAdditionalPricings + values.id + '/').then(() => {
      refreshList();
    });

  return (
    <Box sx={{ mt: 2 }}>
      <h3>Tarifs lignes supplémentaires</h3>
      {listIsVisible && (
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          resultsField={resultsField}
          inlineEditOptions={{
            onSave: onSave,
            onDelete: onDelete,
            validationSchema: Yup.object().shape({
              year: Yup.number().required('Champ obligatoire'),
              product_accounting_code: Yup.string().required('Champ obligatoire'),
              product_accounting_name: Yup.string().required('Champ obligatoire'),
              product_subcontractor_name: Yup.string().required('Champ obligatoire'),
              price: Yup.number().required('Champ obligatoire'),
              start_date: Yup.date()
                .typeError("La date n'est pas au bon format")
                .required('Champ obligatoire'),
              end_date: Yup.date()
                .typeError("La date n'est pas au bon format")
                .required('Champ obligatoire'),
              capex_opex: Yup.string().required('Champ obligatoire'),
              max_usage_limit: Yup.number().nullable()
            })
          }}
          displayPaginationOptions={false}
        />
      )}
      <BirdzNotif options={notifOptions} />
    </Box>
  );
};

export default AdditionalPricings;
