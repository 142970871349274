import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import {
  BirdzNotif,
  CheckboxField,
  ListField,
  ListPage,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { Box } from '@mui/material';
import { formatToSelectOptions, useGetGroups, useGetTechnicians } from '../../../hooks/datarefs';
import SelectFieldWrapper from '../../Tools/Selects/SelectFieldWrapper';

interface FormValues {
  user_id: number | null;
  main_contact: boolean;
  groups: number[] | null;
  id?: number;
  user?: number;
  subcontractor?: number;
}

interface DeleteValue {
  id: number;
}

const Technicians = () => {
  const [listIsVisible, setListIsVisible] = useState<boolean>(true);
  const { id } = useParams();
  const endpoint = `/api/boi/subcontractors/subcontractors/${id}/`;
  const resultsField = 'technicians';
  const { data: groups } = useGetGroups();
  const { data: technicians } = useGetTechnicians();
  const { notif, notifOptions } = useNotif();
  const [listFields, setListFields] = useState<ListField[]>([]);

  useEffect(() => {
    const listFields: ListField[] = [
      {
        name: 'user',
        label: 'Technicien',
        transform: (value: any, object: any) => object?.user__name,
        options: {
          renderFormAdd: (currentRow: any, results: any) => (
            <SelectFieldWrapper
              id="user"
              name="user"
              options={
                technicians
                  ? formatToSelectOptions(
                      technicians.filter((technician) => technician.subcontractor === id),
                      {
                        value: 'user',
                        label: 'user__name'
                      }
                    )
                  : []
              }
              selectedItems={results.map((result: any) => result.user)}
            />
          ),
          renderFormEdit: (currentRow: any) => <>{currentRow.user__name}</>
        }
      },
      {
        name: 'groups',
        label: "Gestionnaire / Chef d'équipe /technicien",
        transform: (values: any) => {
          return values
            .map((value: number) => {
              const group = groups?.find((dataServiceGroup: any) => dataServiceGroup.id === value);
              return group?.name || value;
            })
            .join(', ');
        },
        options: {
          renderForm: () => (
            <SelectFieldWrapper
              id="group"
              name="groups"
              options={
                groups
                  ? formatToSelectOptions(groups, {
                      label: 'name'
                    })
                  : []
              }
              isMulti
            />
          )
        }
      },
      {
        name: 'main_contact',
        label: 'Contact Principal',
        options: {
          renderForm: () => <CheckboxField id="mainContact" name="main_contact" label="" />
        }
      }
    ];
    setListFields(listFields);
  }, [groups, technicians]);

  const refreshList = () => {
    setListIsVisible(false);
    setTimeout(() => {
      setListIsVisible(true);
    });
  };

  const showServerError = (error: any) => {
    let message = 'Une erreur serveur est survenue, merci de réessayer ultérieurement';

    if (error?.response?.data?.message) {
      message += '<br /><br />Erreur:' + error?.response?.data?.message;
    }

    notif({
      content: message,
      type: 'error'
    });
  };

  const onSave = (values: FormValues) => {
    let payload: FormValues = Object.assign({}, values);
    payload.main_contact = payload.main_contact ?? false;
    payload.subcontractor = id ? +id : undefined;

    if (values?.id) {
      if (values.user) payload = { ...payload, user: values.user };
      return axios.put(`/api/boi/subcontractors/technicians/${values.id}/`, payload).then(
        () => {
          notif({
            type: 'success',
            content: 'Le technicien a été modifié avec succès'
          });
          refreshList();
        },
        (error: any) => showServerError(error)
      );
    } else {
      return axios.post(`/api/boi/subcontractors/technicians/`, payload).then(
        () => {
          notif({
            type: 'success',
            content: 'Le technicien a été créé avec succès'
          });
          refreshList();
        },
        (error: any) => showServerError(error)
      );
    }
  };

  const onDelete = (values: DeleteValue) => {
    return axios
      .delete(`/api/boi/subcontractors/technicians/${values.id}/`)
      .then(() => {
        notif({
          content: 'Le technicien a été supprimé avec succès',
          type: 'success'
        });
        refreshList();
      })
      .catch((error: any) => showServerError(error));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <h3>Techniciens / Contact</h3>
      {listIsVisible && (
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          resultsField={resultsField}
          inlineEditOptions={{
            onSave: onSave,
            onDelete: onDelete,
            validationSchema: Yup.object().shape({
              user: Yup.number().required('Champ obligatoire'),
              groups: Yup.array().required('Vous devez choisir au moins un groupe')
            })
          }}
          displayPaginationOptions={false}
        />
      )}
      <BirdzNotif options={notifOptions} />
    </Box>
  );
};

export default Technicians;
