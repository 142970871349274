import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import * as Yup from 'yup';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import {
  BirdzNotif,
  ListPage,
  TimePickerField,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { formatToSelectOptions, useGetCaseEvents } from '../../../hooks/datarefs';
import { CrInterventionEvent } from './Types';
import { authService } from '../../..';
import SelectFieldWrapper from '../../Tools/Selects/SelectFieldWrapper';

interface EventsCrProps {
  id: number | undefined;
  crEvents: CrInterventionEvent[];
  onEventsChange(event: CrInterventionEvent[]): void;
  readOnly: boolean;
}

const CrCaseEvents = ({ id, crEvents, onEventsChange, readOnly = false }: EventsCrProps) => {
  const endpoint = '/api/boi/cases/events-cr-cases/';
  const [listIsVisible, setListIsVisible] = useState<boolean>(true);
  const { data: caseEvents } = useGetCaseEvents();

  const { notif, notifOptions } = useNotif();

  const listFields = [
    {
      name: 'date',
      label: 'Date',
      transform: (date: string) => {
        return moment(date, 'YYYY-MM-DD HH:mmZ').format('DD/MM/YYYY à HH:mm');
      },
      options: {
        renderForm: () => <TimePickerField name="date" size="small" />
      }
    },
    {
      name: 'step__name',
      label: 'Etape',
      options: {
        renderForm: () => (
          <SelectFieldWrapper
            name="step"
            label="Étape"
            options={formatToSelectOptions(caseEvents)}
          />
        )
      }
    }
  ];

  const refreshList = (): void => {
    setListIsVisible(false);
    setTimeout(() => {
      setListIsVisible(true);
    });
  };

  const onSave = (crEventsvalues: CrInterventionEvent) => {
    const payload = Object.assign({}, crEventsvalues);
    payload.case = id;
    if (crEventsvalues?.id) {
      return axios.put(`${endpoint}${crEventsvalues.id}/`, payload).then((response) => {
        const newEvents = crEvents.map((event: CrInterventionEvent) => {
          if (event.id === response.data.id) {
            return response.data;
          }
          return event;
        });
        onEventsChange(newEvents);
        notif({
          type: 'success',
          content: "L'évènement modifié avec succès"
        });
        refreshList();
      });
    } else {
      return axios.post(endpoint, payload).then((response) => {
        const newCrEvent = response.data;
        onEventsChange([...crEvents, newCrEvent]);
        notif({
          type: 'success',
          content: "L'évènement a été créé avec succès"
        });
        refreshList();
      });
    }
  };

  const onDelete = (crEvent: CrInterventionEvent) =>
    axios.delete(endpoint + crEvent.id + '/').then(() => {
      const remainingEvents = crEvents.filter((e) => e.id !== crEvent.id);
      onEventsChange(remainingEvents);
      refreshList();
    });

  return (
    <>
      <Accordion
        variant="outlined"
        square
        sx={{ mt: 2 }}
        defaultExpanded={true}
        disableGutters={true}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ mb: 0 }}>
          <h3>Évènements</h3>
        </AccordionSummary>
        <AccordionDetails>
          {listIsVisible && (
            <ListPage
              fields={listFields}
              loadedData={_.cloneDeep(crEvents)}
              inlineEditOptions={
                authService.canAccess('CR_EDIT_INTER')
                  ? {
                      onSave: readOnly === false ? onSave : undefined,
                      onDelete: readOnly === false ? onDelete : undefined,
                      validationSchema: Yup.object().shape({
                        date: Yup.date()
                          .typeError("La date n'est pas au bon format")
                          .required('Champ obligatoire'),
                        step: Yup.number().required('Champ obligatoire')
                      })
                    }
                  : {}
              }
              paginationOptions={{ pageSize: 10 }}
              displayPaginationOptions={false}
              displayResultsNumber={false}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default CrCaseEvents;
