import React, { useEffect, useState } from 'react';
import {
  BirdzDialog,
  useDialog,
  InputField,
  TimePickerField,
  CheckboxField
} from '@applications-terrains/birdz-react-library';
import { useNavigate, useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Box, Button, CircularProgress, Grid, Paper, TextField } from '@mui/material';

import {
  formatToSelectOptions,
  useGetClients,
  useGetErps,
  useGetExportFormats,
  useGetInterventionTypes,
  useGetModuleFamilies
} from '../../../hooks/datarefs';
import { Formik, Form } from 'formik';
import { SendingType } from './SendingType';
import { Cron } from './Cron';
import { useSaveJob } from './hooks/useSaveJob';
import * as Yup from 'yup';
import CustomSwitchField from '../../CustomSwitchField/CustomSwitchField';
import { capitalizeFirstLetter } from '../../../utils';
import { Erp } from '../Erps/ErpForm';
import { getERPNameAndVersion } from '../Erps/utils';
import SelectFieldWrapper from '../../Tools/Selects/SelectFieldWrapper';

export type Job = {
  id: number;
  name: string;
  active: boolean;
  client: number;
  erp: number;
  module_families: string[];
  intervention_types: string[];
  programming_type: string;
  cron: string;
  start_at: string;
  credentials_sending_export: {
    id: number;
    sending_type: string;
    server: string;
    user: string;
    password: string;
    private_key: null | string;
  }[];
};

export const translations = {
  intervention_types: "types d'intervention",
  name: 'nom',
  client: 'client',
  export_format: 'format',
  erp: 'erp',
  module_families: 'familles de modules',
  spontaneous_interventions: 'interventions opportunistes',
  scheduled_interventions: 'interventions programmées',
  active: 'actif / inactif',
  password: 'mot de passe'
};

const JobForm = () => {
  const endpoint = '/api/boi/extractor/jobs/';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationSchema, setValidationSchema] = useState<Yup.ObjectShape>({});
  const [job, setJob] = useState<Partial<Job>>({
    intervention_types: []
  });
  const navigate = useNavigate();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { saveJob, submitInProgress, notif } = useSaveJob(translations);

  const { id } = useParams<{ id?: string }>();
  const { data: clients } = useGetClients();
  const { data: erps } = useGetErps();
  const { data: exportFormats } = useGetExportFormats();
  const { data: moduleFamilies } = useGetModuleFamilies();
  const { data: interventionTypes } = useGetInterventionTypes();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios.get(`${endpoint}${id}/`).then((response: AxiosResponse<Job>) => {
        setJob(response.data);
        setIsLoading(false);
      });
    }
  }, [id]);

  if (isLoading || !clients || !erps || !exportFormats || !moduleFamilies || !interventionTypes) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <h3>Chargement en cours...</h3>
      </Box>
    );
  }

  return (
    <Paper variant="outlined" square sx={{ p: 2 }}>
      <h3>{id ? 'Modifier le job' : 'Ajouter le job'}</h3>

      <Formik
        initialValues={job}
        enableReinitialize={true}
        onSubmit={async (values: any) => {
          const fieldsToConfirm = Object.entries(values.credentials_sending_export)
            .map(([key, value]) => {
              const correspondance = {
                password: 'mot de passe',
                private_key: 'clé privée'
              };
              if (
                (key === 'password' || key === 'private_key') &&
                job.credentials_sending_export &&
                job.credentials_sending_export[key as any] &&
                job.credentials_sending_export[key as any] !== value
              ) {
                if (value) return correspondance[key];
              } else return undefined;
            })
            .filter((el) => el) as string[];

          fieldsToConfirm.length
            ? confirmDialog({
                onValidate: () => saveJob(values),
                title: "Confirmer l'enregistrement",
                content: (
                  <div>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      {"Confirmez-vous l'enregistrement pour les champs suivants ?"}
                    </div>
                    <ul>
                      {fieldsToConfirm.map((field) => (
                        <li key={field}>{field}</li>
                      ))}
                    </ul>
                  </div>
                ),
                onCancel: () => {
                  closeDialog();
                },
                okButtonLabel: 'Oui',
                cancelButtonLabel: 'Non'
              })
            : saveJob(values);
        }}
        validationSchema={Yup.object(validationSchema)}
      >
        {(props: any) => {
          const { errors, setSubmitting, handleSubmit, setFieldValue, values } = props;
          return (
            <Form
              id="topForm"
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                setSubmitting(true);
                if (Object.keys(errors).length === 0) {
                  handleSubmit();
                }
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid alignItems={'center'} item xs={2}>
                  {capitalizeFirstLetter(translations['name'])} *
                </Grid>
                <Grid alignItems={'center'} item xs={4}>
                  <InputField name="name" type="text" size="small" fullWidth />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid alignItems={'center'} item xs={4}>
                  <CustomSwitchField name="active" label="Inactif / Actif" />
                </Grid>
                <Grid alignItems={'center'} item xs={2}>
                  {capitalizeFirstLetter(translations['client'])} *
                </Grid>
                <Grid alignItems={'center'} item xs={4}>
                  <SelectFieldWrapper
                    name="client"
                    options={formatToSelectOptions(clients, {
                      label: 'name'
                    })}
                    required={true}
                    sx={{ width: 300 }}
                  />
                </Grid>
                <Grid alignItems={'center'} textAlign="right" item xs={2}>
                  {translations['erp'].toUpperCase()} *
                </Grid>
                <Grid alignItems={'center'} item xs={4}>
                  <SelectFieldWrapper
                    name="erp"
                    options={formatToSelectOptions(
                      erps.map((erp: Erp) => ({
                        ...erp,
                        label: getERPNameAndVersion(erp)
                      }))
                    )}
                    required={true}
                    sx={{ width: 300 }}
                  />
                </Grid>
                <Grid alignItems={'center'} item xs={2}>
                  {capitalizeFirstLetter(translations['intervention_types'])} *
                </Grid>
                <Grid alignItems={'center'} item xs={10}>
                  <SelectFieldWrapper
                    name="intervention_types"
                    options={formatToSelectOptions(interventionTypes, {
                      label: 'Name',
                      value: 'Name'
                    })}
                    isMulti
                    required={true}
                  />
                </Grid>
                <Grid alignItems={'center'} item xs={2}>
                  {capitalizeFirstLetter(translations['export_format'])}
                </Grid>
                <Grid alignItems={'center'} item xs={10}>
                  <SelectFieldWrapper
                    name="export_format"
                    options={formatToSelectOptions(exportFormats, {
                      label: 'name',
                      value: 'id'
                    })}
                    required={true}
                  />
                </Grid>
                <Grid alignItems={'center'} item xs={2}>
                  {capitalizeFirstLetter(translations['module_families'])}
                </Grid>
                <Grid alignItems={'center'} item xs={10}>
                  <SelectFieldWrapper
                    name="module_families"
                    options={formatToSelectOptions(moduleFamilies, {
                      label: 'Name',
                      value: 'Name'
                    })}
                    required={true}
                    isMulti
                  />
                </Grid>
                <Grid alignItems={'center'} item xs={2}>
                  {capitalizeFirstLetter(translations['spontaneous_interventions'])}
                </Grid>
                <Grid alignItems={'center'} item xs={10}>
                  <CheckboxField
                    id="spontaneous_interventions"
                    name="spontaneous_interventions"
                    label=""
                    className="mt-25"
                  />
                </Grid>
                <Grid alignItems={'center'} item xs={2}>
                  {capitalizeFirstLetter(translations['scheduled_interventions'])}
                </Grid>
                <Grid alignItems={'center'} item xs={10}>
                  <CheckboxField
                    id="scheduled_interventions"
                    name="scheduled_interventions"
                    label=""
                    className="mt-25"
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <h3>Types d'envois</h3>
                <SendingType
                  setValidationSchema={setValidationSchema}
                  validationSchema={validationSchema}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <h3>Programmation *</h3>
                <TimePickerField name="start_at" size="small" label="Date / heure de démarrage" />
                <Cron />
              </Box>

              <Box sx={{ mt: 2 }}>
                <h3>Contacts à prévenir</h3>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ width: '350px' }}>Sur activation / désactivation du job</Box>
                  <TextField
                    name="activate_job_contacts"
                    multiline
                    rows={4}
                    type="textarea"
                    variant="outlined"
                    sx={{
                      m: 1,
                      width: '100%'
                    }}
                    onChange={(e) => {
                      setFieldValue('activate_job_contacts', e.target.value.split('\n'));
                    }}
                    placeholder="Saisir un email par ligne"
                    value={values.activate_job_contacts?.join('\n') ?? ''}
                  />
                </Box>
              </Box>

              <Box sx={{ textAlign: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  form="topForm"
                  sx={{ m: 2 }}
                  disabled={submitInProgress}
                >
                  {submitInProgress && <CircularProgress size={18} thickness={5} />} Enregistrer
                </Button>

                <Button
                  className="btn-space"
                  onClick={() => {
                    confirmDialog({
                      title: 'Annuler les saisies',
                      content: 'Souhaitez-vous annuler vos saisies ?',
                      onValidate: () => {
                        closeDialog();
                        navigate(-1);
                      },
                      onCancel: () => {
                        closeDialog();
                      },
                      okButtonLabel: 'Oui',
                      cancelButtonLabel: 'Non'
                    });
                  }}
                >
                  Annuler
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
      {notif}
      <BirdzDialog options={dialogOptions} />
    </Paper>
  );
};

export default JobForm;
