import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Button, FormControl, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { MRCASETYPE, MrCase } from './Types';
import { InputField } from '@applications-terrains/birdz-react-library';
import { formatToSelectOptions, useGetSubcontractorsMR } from '../../../hooks/datarefs';
import { FaGoogleDrive } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import CrCaseDriveAssociationModal from '../../CR/Cases/CrCaseDriveAssociationModal';
import MrInterventionType from './MrInterventionType';
import SelectFieldWrapper from '../../Tools/Selects/SelectFieldWrapper';

type MrInterventionFormProps = {
  mrCase: Partial<MrCase>;
  onCreateWzPhoenix: () => void;
};

const MrCaseInterventionForm = ({ mrCase, onCreateWzPhoenix }: MrInterventionFormProps) => {
  const { values, setFieldValue, getFieldProps } = useFormikContext<any>();
  const [modalDriveIsOpen, setModalDriveIsOpen] = React.useState(false);
  const networkTypologies = [
    { value: 'DSP', label: 'DSP' },
    { value: 'IA', label: 'IA' }
  ];

  const { data: subcontractors } = useGetSubcontractorsMR();

  const onSubcontractorChange = (subcontractorId: number | undefined) => {
    if (!subcontractorId) return;
    const foundSubcontractor = subcontractors?.find(
      (subcontractor: any) => subcontractor.id === subcontractorId
    );

    if (foundSubcontractor) {
      return foundSubcontractor;
    } else {
      return null;
    }
  };

  const handleCloseModalDrive = () => {
    setModalDriveIsOpen(false);
  };

  return (
    <>
      <Grid item xs={12} md={7}>
        <h4>Interventions</h4>
        <Stack spacing={2} sx={{ width: '400px' }}>
          <FormControl>
            <SelectFieldWrapper
              id="subcontractor"
              onChange={(e: any, selectedSubcontractor: any) => {
                const subcontractor = onSubcontractorChange(selectedSubcontractor?.value);
                setFieldValue('subcontractor', selectedSubcontractor?.value);
                setFieldValue('main_contact__email', subcontractor?.main_contact);
              }}
              name="subcontractor"
              label="Sous-traitant"
              options={formatToSelectOptions(subcontractors, {
                label: 'name'
              })}
              required={true}
              sx={{ width: 300 }}
            />
          </FormControl>
          <Box>
            {values?.subcontractor && (
              <>Contact principal : {values?.main_contact__email || 'aucun'}</>
            )}
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12} md={5} sx={{ mt: 2 }}>
        <h4>Finalité</h4>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <FormControl>
            <InputField
              id="observation"
              multiline
              rows={4}
              fullWidth
              label="Observation (non visible sous-traitant)"
              {...getFieldProps('observation')}
            />
          </FormControl>
          <FormControl>
            <InputField
              id="comment"
              multiline
              rows={4}
              fullWidth
              label="Commentaire zone"
              {...getFieldProps('comment')}
            />
          </FormControl>
        </Stack>
      </Grid>
      <Grid container spacing={2}>
        {mrCase.action_type === MRCASETYPE.DEPLOYMENT && (
          <Grid item xs={12} md={6}>
            <Stack spacing={2} sx={{ width: '350px' }}>
              <FormControl>
                <SelectFieldWrapper
                  name="network_topology"
                  label="Type de réseau"
                  options={networkTypologies}
                  required={true}
                  sx={{ width: 300 }}
                />
              </FormControl>
            </Stack>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Stack spacing={2} sx={{ width: '350px' }}>
            <Stack
              direction="row"
              sx={{ display: 'flex', mt: 3 }}
              alignItems={'center'}
              justifyContent={'left'}
            >
              {!values.folder_id ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setModalDriveIsOpen(true);
                  }}
                  sx={{ mr: 3 }}
                >
                  Association drive
                  <Box sx={{ ml: 1 }}>
                    <FaGoogleDrive />
                  </Box>
                </Button>
              ) : (
                <>
                  <FaGoogleDrive />
                  <Box sx={{ ml: 1 }}>
                    Association drive : <strong>{values.folder_name}</strong>
                  </Box>
                  <Tooltip title="Supprimer l'association au drive">
                    <IconButton
                      onClick={() => {
                        setFieldValue('folder_id', undefined);
                        setFieldValue('folder_name', undefined);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Stack>
          </Stack>
        </Grid>
        <CrCaseDriveAssociationModal
          selectedValue={values.folder_id}
          open={modalDriveIsOpen}
          onClose={(folderId: string | undefined, driveName: string | undefined) => {
            handleCloseModalDrive();
            if (folderId) {
              setFieldValue('folder_id', folderId);
              setFieldValue('folder_name', driveName);
            } else {
              setFieldValue('folder_id', null);
              setFieldValue('folder_name', null);
            }
          }}
        />

        {mrCase.action_type === MRCASETYPE.MAINTENANCE && (
          <Grid container item sx={{ mt: 2 }}>
            <MrInterventionType mrCase={mrCase} />
          </Grid>
        )}
      </Grid>

      <Grid container item sx={{ mt: 3, alignItems: 'center', justifyContent: 'center' }}>
        <Button
          disabled={
            typeof mrCase?.phoenix_zone_id === 'number' ||
            (mrCase.action_type === MRCASETYPE.DEPLOYMENT && !values.network_topology)
          }
          variant="contained"
          onClick={onCreateWzPhoenix}
        >
          Créer la zone de travaux sur Phoenix
        </Button>
      </Grid>
    </>
  );
};

export default MrCaseInterventionForm;
