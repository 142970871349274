import React, { useEffect, useState } from 'react';
import { BirdzTitle } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import axios from 'axios';
import { useGlobalContext } from '../../../../contexts/globalContext';
import SelectFieldWrapper from '../../../Tools/Selects/SelectFieldWrapper';

export const ExportFormatSettingsMapping = () => {
  const [dataMapping, setDataMapping] = useState<null | []>(null);
  const { values } = useGlobalContext();

  useEffect(() => {
    dataMapping === null &&
      values.erp &&
      typeof values.erp === 'number' &&
      axios.get(`/api/boi/extractor/data-mapping/?erp=${values.erp}`).then((response) => {
        setDataMapping(response?.data?.results || []);
      });
  }, [dataMapping, values]);

  return (
    <>
      <BirdzTitle>Mapping</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Correspondance
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <SelectFieldWrapper
            name="data_mapping"
            options={
              dataMapping && dataMapping.length > 0
                ? dataMapping.map((mapping: any) => {
                    return {
                      value: mapping.id,
                      label: `${mapping.name} (#${mapping.id})`
                    };
                  })
                : []
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
